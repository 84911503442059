<template>
    <div class="weekly-ranking">
      <PromoHeader :height="headerHeight" />
      <div class="main-info" :style="{ marginTop: headerHeight }">
        <img src="@/assets/images/h5/Inside/Inside-h5-6-1.png" alt="" />
      </div>
    </div>
  </template>
  
  <script>
  import elementHeight from "@/mixins/h5/elementHeight";
  import PromoHeader from "@/components/h5/PromoHeader";
  
  export default {
    name: "WeeklyRanking",
    mixins: [elementHeight],
    components: {
      PromoHeader,
    },
  };
  </script>
  
  <style lang="less" scoped>
  .weekly-ranking {
    overflow: hidden;
    background: #fff;
  
    .main-info {
      img {
        width: 100%;
      }
    }
  }
  </style>
  